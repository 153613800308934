<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">订单数据</div>
      <div class="formDiv" style="width: 70vw; margin-left: 10vw">
        <el-form label-position="left" label-width="90px">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="订单号:">
                <el-input
                  v-model="FormData.order_id"
                  placeholder="订单编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入设备编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="设备编号:">
                <el-input
                  v-model="FormData.device_id"
                  placeholder="请输入设备编号"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form label-position="left" label-width="90px">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="车型:">
                <el-select
                  v-model="FormData.type_id"
                  placeholder="请选择充电类型"
                >
                  <el-option
                    v-for="car in carList"
                    :key="car.value"
                    :value="car.value"
                    :label="car.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="6">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单时间：" class="time_pick">
                <el-date-picker
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  unlink-panels
                  range-separator=" - "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
            <!-- <el-col :span="1" :offset="1">
                <div class="BtnSearch" @click="handleExcel()">导出</div>
              </el-col>
              <el-col :span="1" :offset="1">
                <div class="BtnSearch" @click="handlePrint()">打印</div>
              </el-col> -->
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          :data="tableData"
          border
          v-loading="loading"
          height="510px"
          style="
            width: 90%;
            margin: 20px auto;

            overflow-y: auto;
          "
        >
          <!-- <el-table-column type="selection" /> -->
          <!-- <el-table-column label="编号" type="index" width="60" /> -->
          <el-table-column label="订单编号" prop="order_id" />
          <el-table-column label="手机号" prop="phone" />
          <el-table-column label="设备编号" prop="device_id" />
          <el-table-column label="充电类型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <el-table-column
            label="充电时长(分钟)"
            prop="cost_time"
            width="120"
            :formatter="rounding"
          />
          <el-table-column label="开始时间" prop="start_time" />
          <el-table-column label="结束时间" prop="end_time" />
          <el-table-column
            label="支付金额(元)"
            prop="pay_money"
            :formatter="rounding"
          />
          <el-table-column
            label="支付方式"
            prop="pattern_of_payment"
            width="100"
          />
          <el-table-column
            label="电费(元)"
            prop="pay_money"
            width="80"
            :formatter="rounding"
          />
          <el-table-column label="实际收入" prop="pay_money" />
          <el-table-column label="所属运营商" prop="username" />
        </el-table>
        <div class="pagination">
          <div class="flex1">
            <p>充值总次数：<span>111</span></p>
            <p>充值总金额：<span>111</span></p>
          </div>
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
// import { onMounted } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_history_order, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import ProvinceCityCountry from "../../assets/json/address";
export default {
  name: "UserRecord",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime: ["", ""],
      },
      isadmin: false,
      users: [],
      tableData: [
        {
          user_id: 11,
          contact_way: 13256561235,
          wx_id: "001",
          mailbox: "CD001",
        },
      ],
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",

      loading: false,
      provinceData: ProvinceCityCountry.address,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
    });
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      return (
        dt.getFullYear() +
        "-" +
        (dt.getMonth() + 1) +
        "-" +
        dt.getDate() +
        " " +
        dt.getHours() +
        ":" +
        dt.getMinutes() +
        ":" +
        dt.getSeconds()
      );
    };
    const searchBtn = () => {
      getList();
    };

    const handleExcel = () => {
      console.log("导出");
    };

    const handlePrint = () => {
      console.log("打印");
    };

    const getList = () => {
      console.log(data.FormData, "data.FormData");
      const stDate = data.FormData.orderTime[0]
        ? data.FormData.orderTime[0]
        : undefined;
      const endDate = data.FormData.orderTime[1]
        ? data.FormData.orderTime[1]
        : undefined;
      const dataa = {
        username: data.FormData.username,
        operator_id: sessionStorage.getItem("userID"),
        phone: data.FormData.phone,
        type_id: data.FormData.type_id,
        device_id: data.FormData.device_id,
        order_id: data.FormData.order_id,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        page:data.currentPage
      };
      data.loading = true;
      sel_history_order(dataa).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            console.log(res, "获取历史订单列表");
            data.tableData = res.data;
            data.total = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList()
      //
    };
    onMounted(() => {
      console.log(data);
      getUsername();
      getList();
    });
    return {
      ...toRefs(data),
      formatDate,
      searchBtn,
      getList,
      getUsername,
      handleExcel,
      handlePrint,
      handleSizeChange,
      handleCurrentChange,
      rounding,
    };
  },
};
</script>
  
  <style>
.formDiv {
  width: 75%;
}
.bottomDiv {
  height: 100%;
}
.BtnSearch1 {
  /* float: left; */
  width: 100px;
  margin: 0 auto;
}
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}
.flex1 {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  /* margin-top: -10px; */
}
.flex1 p {
  font-size: 14px;
  margin-right: 15px;
}
.flex1 span {
  color: red;
}
</style>